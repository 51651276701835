import React, {Component} from "react";
import Item from "./Item";

class Items extends Component {
    state = {
        items: [],
        groups: [],
        isTrash: false
    };

    async componentDidMount() {
        if (this.requiresLogin()) {
            return;
        }
        let trash = this.props.location.pathname === "/items/trash";
        let url = '/api/v1/links';
        if (trash) {
            this.setState({isTrash: true});
            url += '?trash=1'
        }
        fetch(url, {
            method: "POST",
            headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
            body: JSON.stringify({session_id: localStorage.getItem("session_id")})
        })
            .then((response) => response.json())
            .then((responseData) => {
                if (responseData.code === 401) {
                    return this.props.history.push("/user");
                }
                if (responseData.code === 200 && responseData.data) {
                    this.setState({items: responseData.data});
                }
            });
    }

    requiresLogin() {
        if (!localStorage.getItem('session_id')) {
            this.props.history.push("/user");
            return true;
        }
        return false;
    }

    render() {
        const {items, isTrash} = this.state;
        let trashButton;
        if (isTrash) {
            trashButton = <a className="trashed" href="/#/items">View items</a>;
        } else {
            trashButton = <a className="trashed" href="/#/items/trash">View trash</a>;
        }

        return <section className="module-container">
            <header>
                <div className="section-title">
                    Application list
                    {trashButton}

                </div>
                <div className="module-actions">
                    <a href="/#/items/create" title="" className="button">
                        <i className="fa fa-plus"/><span>Add</span></a>
                    <a href="/" className="button"><i className="fa fa-ban"/><span>Cancel</span></a>
                </div>
            </header>

            <table className="table table-hover">
                <thead>
                <tr>
                    <th>Title</th>
                    <th>URL</th>
                    <th className="text-center" width="100">Edit</th>
                    <th className="text-center" width="100">Delete</th>
                </tr>
                </thead>
                <tbody>
                {items && items.map(function (item) {
                    return <Item item={item} key={item.id}/>
                })}
                </tbody>
            </table>
        </section>;
    }
}

export default Items;
