import React, {Component} from 'react';
import {HashRouter as Router, Route} from "react-router-dom";
import './App.css';
import SearchForm from './layout/SearchForm.js';
import Links from "./links/Links";
import Buttons from "./layout/Buttons";
import Items from "./links/Items";
import ItemCreate from "./links/Create";
import UsersList from "./users/List";
import UserCreate from "./users/Create";
import UserSelect from "./users/Select";
import UserLogin from "./users/Login";

class App extends Component {
    state = {
        isLoading: true,
        groups: []
    };

    render() {
        return (
            <Router>
                <div id="app">
                    <nav className="sidebar"/>
                    <div className="content">
                        <main>
                            <Route exact={true} path="/" component={SearchForm}/>
                            <Route exact={true} path="/" component={Links}/>
                            <Route exact={true} path="/items/create" component={ItemCreate}/>
                            <Route exact={true} path="/items" component={Items}/>
                            <Route exact={true} path="/items/trash" component={Items}/>
                            <Route exact={true} path="/items/:id/edit" component={ItemCreate}/>
                            <Route exact={true} path="/users/list" component={UsersList}/>
                            <Route exact={true} path="/users/create" component={UserCreate}/>
                            <Route exact={true} path="/user/:id/edit" component={UserCreate}/>
                            <Route exact={true} path="/user" component={UserSelect}/>
                            <Route exact={true} path="/user/login/:id" component={UserLogin}/>
                            <Buttons/>
                        </main>
                    </div>
                </div>
            </Router>
        );
    }
}

export default App;
