import React, {Component} from "react";

class Select extends Component {
    state = {
        items: null
    };

    async componentDidMount() {
        let link = '/api/v1/users';
        const response = await fetch(link);
        const body = await response.json();
        if (body.code !== 404) {
            if (body.success && (!body.data || body.data.length === 0)) {
                console.log('redirecting to new thing');
                this.props.history.push("/users/create");
            }
            this.setState({items: body.data});
        }
    }

    render() {
        const {items} = this.state;

        return <div className="userlist">
            {items !== null && items.map(function (item) {
                return <a className="user" href={"#/user/login/" + item.id} key={item.id}>
                    <img className="user-img" alt={item.username} src="/static/img/avatar.png"/>
                    {item.username}
                </a>
            })}
        </div>;
    }
}

export default Select;
