import React, {Component} from "react";
import Title from "../form/Title";
import TextField from "@material-ui/core/TextField/TextField";
import {CompactPicker as ColorPicker} from 'react-color';

const suggestions = [
    {value: "AirSonic", label: "AirSonic", color:"#08F", icon:"/static/icons/airsonic.png"},
    {value: "Cardigann", label: "Cardigann", color:"#753", icon:"/static/icons/cardigann.png"},
    {value: "CouchPotato", label: "CouchPotato", color:"#363840", icon:"/static/icons/couchpotato.png"},
    {value: "Bazarr", label: "Bazarr", color:"#222", icon:"/static/icons/bazarr.png"},
    {value: "Booksonic", label: "Booksonic", color:"#58a", icon:"/static/icons/booksonic.png"},
    {value: "BookStack", label: "BookStack", color:"#02679E", icon:"/static/icons/bookstack.png"},
    {value: "Deluge", label: "Deluge", color:"#222", icon:"/static/icons/deluge.png"},
    {value: "Dokuwiki", label: "Dokuwiki", color:"#9d7056", icon:"/static/icons/dokuwiki.png"},
    {value: "Duplicati", label: "Duplicati", color:"#2c3744", icon:"/static/icons/duplicati.png"},
    {value: "Emby", label: "Emby", color:"#222", icon:"/static/icons/emby.png"},
    {value: "Flood", label: "Flood", color:"#00D091", icon:"/static/icons/flood.png"},
    {value: "FreshRSS", label: "FreshRSS", color:"#003B73", icon:"/static/icons/freshrss.png"},
    {value: "Gitea", label: "Gitea", color:"#585e52", icon:"/static/icons/gitea.png"},
    {value: "Glances", label: "Glances", color:"#2c363f", icon:"/static/icons/glances.png"},
    {value: "Grafana", label: "Grafana", color:"#a56e4d", icon:"/static/icons/grafana.png"},
    {value: "Graylog", label: "Graylog", color:"#158", icon:"/static/icons/graylog.png"},
    {value: "Headphones", label: "Headphones", color:"#185", icon:"/static/icons/headphones.png"},
    {value: "Home Assistant", label: "Home Assistant", color:"#073c52", icon:"/static/icons/homeassistant.png"},
    {value: "Jackett", label: "Jackett", color:"#484814", icon:"/static/icons/jackett.png"},
    {value: "Jdownloader", label: "Jdownloader", color:"#2b494f", icon:"/static/icons/jdownloader.png"},
    {value: "Krusader", label: "Krusader", color:"#5A5", icon:"/static/icons/krusader.png"},
    {value: "LibreNMS", label: "LibreNMS", color:"#222", icon:"/static/icons/librenms.png"},
    {value: "Lidarr", label: "Lidarr", color:"#183c18", icon:"/static/icons/lidarr.png"},
    {value: "Mcmyadmin", label: "Mcmyadmin", color:"#30404b", icon:"/static/icons/mcmyadmin.png"},
    {value: "Medusa", label: "Medusa", color:"#4b5e55", icon:"/static/icons/medusa.png"},
    {value: "MusicBrainz", label: "MusicBrainz", color:"#a0a", icon:"/static/icons/musicbrainz.png"},
    {value: "Mylar", label: "Mylar", color:"#aa0", icon:"/static/icons/mylar.png"},
    {value: "NZBGet", label: "NZBGet", color:"#253827", icon:"/static/icons/nzbget.png"},
    {value: "Netdata", label: "Netdata", color:"#543737", icon:"/static/icons/netdata.png"},
    {value: "Nextcloud", label: "Nextcloud", color:"#0e2c3e", icon:"/static/icons/nextcloud.png"},
    {value: "Now Showing", label: "Now Showing", color:"#690000", icon:"/static/icons/nowshowing.png"},
    {value: "Nzbhydra", label: "Nzbhydra", color:"#53644d", icon:"/static/icons/nzbhydra.png"},
    {value: "OPNSense", label: "OPNSense", color:"#211914", icon:"/static/icons/opnsense.png"},
    {value: "Ombi", label: "Ombi", color:"#150f09", icon:"/static/icons/ombi.png"},
    {value: "Openhab", label: "Openhab", color:"#2b2525", icon:"/static/icons/openhab.png"},
    {value: "OpenMediaVault", label: "OpenMediaVault", color:"#5AF", icon:"/static/icons/openmediavault.png"},
    {value: "Pihole", label: "Pihole", color:"#352222", icon:"/static/icons/pihole.png"},
    {value: "Plex", label: "Plex", color:"#222", icon:"/static/icons/plex.png"},
    {value: "Plexpy", label: "Plexpy", color:"#2d2208", icon:"/static/icons/plexpy.png"},
    {value: "Plexrequests", label: "Plexrequests", color:"#3c2d1c", icon:"/static/icons/plexrequests.png"},
    {value: "Portainer", label: "Portainer", color:"#283f44", icon:"/static/icons/portainer.png"},
    {value: "Proxmox", label: "Proxmox", color:"#542e0a", icon:"/static/icons/proxmox.png"},
    {value: "Radarr", label: "Radarr", color:"#3e3726", icon:"/static/icons/radarr.png"},
    {value: "Rancher", label: "Rancher", color:"#78c9cf", icon:"/static/icons/rancher.png"},
    {value: "Runeaudio", label: "Runeaudio", color:"#05A", icon:"/static/icons/runeaudio.png"},
    {value: "Sabnzbd", label: "Sabnzbd", color:"#3e3924", icon:"/static/icons/sabnzbd.png"},
    {value: "Sickrage", label: "Sickrage", color:"#6185a6", icon:"/static/icons/sickrage.png"},
    {value: "Sonarr", label: "Sonarr", color:"#163740", icon:"/static/icons/sonarr.png"},
    {value: "Syncthing", label: "Syncthing", color:"#888", icon:"/static/icons/syncthing.png"},
    {value: "Tautulli", label: "Tautulli", color:"#2d2208", icon:"/static/icons/tautulli.png"},
    {value: "Transmission", label: "Transmission", color:"#950003", icon:"/static/icons/transmission.png"},
    {value: "Traefik", label: "Traefik", color:"#28434a", icon:"/static/icons/traefik.png"},
    {value: "tt-rss", label: "tt-rss", color:"#9d704c", icon:"/static/icons/tt-rss.png"},
    {value: "TVheadend", label: "TVheadend", color:"#006080", icon:"/static/icons/tvheadend.png"},
    {value: "UniFi", label: "UniFi", color:"#363840", icon:"/static/icons/unifi.png"},
    {value: "unRAID", label: "unRAID", color:"#A12624", icon:"/static/icons/unraid.png"},
    {value: "pfSense", label: "pfSense", color:"#243699", icon:"/static/icons/pfsense.png"},
    {value: "pyLoad", label: "pyLoad", color:"#881", icon:"/static/icons/pyload.png"},
    {value: "ruTorrent", label: "ruTorrent", color:"#004", icon:"/static/icons/rutorrent.png"},
    {value: "Watcher3", label: "Watcher3", color:"#500", icon:"/static/icons/watcher3.png"},
    {value: "WebTools", label: "WebTools", color:"#555", icon:"/static/icons/webtools.png"},
];

class Create extends Component {
    state = {
        id: 0,
        title: '',
        url: '',
        pinned: false,
        background: '#ffffff',
        icon: '',
        ord: 100,
        file: null,
    };

    handleImageChange = (e) => {
        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            this.setState({
                file: reader.result,
                fileName: file.name,
                icon: reader.result
            });
        };
        reader.readAsDataURL(file)
    };

    async componentDidMount() {
        let id = this.props.match.params.id;
        if (id) {
            const link = '/api/v1/link/' + id;
            fetch(link, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    session_id: localStorage.getItem("session_id")
                })
            })
                .then((response) => response.json())
                .then((responseData) => {
                    if (responseData.code === 200 && responseData.data) {
                        this.setState({
                            id: responseData.data.id,
                            title: responseData.data.name,
                            url: responseData.data.url,
                            pinned: responseData.data.pinned,
                            background: responseData.data.color,
                            icon: responseData.data.icon,
                            ord: responseData.data.ord,
                        });
                    }
                });
        }
    }

    handleChangeColor = (color) => {
        this.setState({background: color.hex});
    };

    handleChange = (event) => {
        let obj = this.state;
        let value = event.target.value;
        if (event.target.type === 'checkbox') {
            value = event.target.checked;
        }
        obj[event.target.name] = value;
        this.setState(obj);
    };


    handleTitle = (value) => {
        let state = {title: value, background: '#222', icon: ''};
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        let count = 0;

        let items = inputLength === 0 ? [] : suggestions.filter(suggestion => {
            const keep = count < 5 && suggestion.label.slice(0, inputLength).toLowerCase() === inputValue;
            if (keep) {
                count += 1;
            }

            return keep;
        });
        if (items.length > 0) {
            items.forEach(function (key) {
                if (key.value.toLowerCase() === value.toLowerCase()) {
                    state.background = key.color;
                    state.icon = key.icon;
                }
            });
        }

        this.setState(state);
    };

    handleSubmit = (event) => {
        event.preventDefault();
        if (this.state.title && this.state.url) {
            let link = "/api/v1/link/add";
            if (this.state.id) {
                link = "/api/v1/link/" + this.state.id + "/edit"
            }
            fetch(link, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name: this.state.title,
                    url: this.state.url,
                    pinned: this.state.pinned,
                    color: this.state.background,
                    icon: this.state.icon,
                    ord: Number(this.state.ord),
                    file: this.state.file,
                    session_id: localStorage.getItem("session_id")
                })
            })
                .then((response) => response.json())
                .then((responseData) => {
                    if (responseData.code === 401) {
                        return this.props.history.push("/user");
                    }
                    if (responseData.code === 200) {
                        this.props.history.push("/items");
                    }
                });
        }
    };

    render() {
        let icon = "/static/icons/warden.png";
        if (this.state.icon) {
            icon = this.state.icon;
        }

        return <form method="POST" action="#222" id="itemform" onSubmit={this.handleSubmit}
                     encType="multipart/form-data">
            <section className="module-container">
                <header>
                    <div className="section-title">Add application</div>
                    <div className="module-actions">
                        <button type="submit" className="button">
                            <i className="fa fa-save"/>
                            <span>Save</span>
                        </button>
                        <a href="#/items" className="button"><i className="fa fa-ban"/><span>Cancel</span></a>
                    </div>
                </header>
                <div id="create" className="create">

                    <div className="input">
                        <label>Application name *</label>
                        <Title name="title" suggestions={suggestions} onChange={this.handleTitle}
                               value={this.state.title}/>
                        <hr/>
                        <label>URL</label>
                        <TextField placeholder="URL" name="url" value={this.state.url} onChange={this.handleChange}/>
                        <hr/>
                        <label>Pinned</label>
                        <input name="pinned" type="hidden" value="0"/>
                        <label className="switch">
                            <input type="checkbox" name="pinned" value="1"
                                   checked={this.state.pinned} onChange={this.handleChange}/>
                            <span className="slider round"/>
                        </label>
                        <hr/>
                        <label>Ordering number</label>
                        <TextField placeholder="Order" name="ord" value={this.state.ord} onChange={this.handleChange}/>

                    </div>
                    <div className="input">
                        <label>Colour *</label>
                        <ColorPicker
                            color={this.state.background}
                            onChangeComplete={this.handleChangeColor}
                        />
                    </div>
                    <div className="input">
                        <input type="hidden" name="icon" value={icon}/>
                        <label>Icon</label>
                        <div className="icon-container">
                            <div id="appimage">
                                <img src={icon} alt=""/>
                            </div>
                            <div className="upload-btn-wrapper">
                                <button className="btn">Upload a file</button>
                                <input type="file" id="upload" name="file" onChange={this.handleImageChange} />
                            </div>
                        </div>
                    </div>
                </div>
                <footer>
                    <div className="section-title">&nbsp;</div>
                    <div className="module-actions">
                        <button type="submit" className="button">
                            <i className="fa fa-save"/>
                            <span>Save</span>
                        </button>
                        <a href="#/items" className="button"><i className="fa fa-ban"/><span>Cancel</span></a>
                    </div>
                </footer>
            </section>
        </form>;
    }
}
export default Create;
