import React, {Component} from "react";
import PropTypes from "prop-types";
import Item from "../links/Item";

class User extends Component {
    render() {
        const {item, requires_login} = this.props;
        let editLink = "/#/user/" + item.id + "/edit";
        let deleteLink = "/#/user/" + item.id + "/delete";

        return <tr>
            <td>{item.username}</td>
            <td className="text-center" style={{display:requires_login ? 'none' : ''}}>
                <a href={editLink} title="user.settings.edit">
                    <i className="fas fa-edit"/>
                </a>
            </td>
            <td className="text-center" style={{display:requires_login ? 'none' : ''}}>
                <a href={deleteLink} title="user.settings.delete">
                    <i className="fas fa-trash"/>
                </a>
            </td>
        </tr>;
    }
}

Item.propTypes = {
    item: PropTypes.object.isRequired,
    requires_login: PropTypes.object.isRequired,
};

export default User
