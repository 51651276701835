import React, {Component} from "react";

class Create extends Component {
    state = {
        id: 0,
        username: '',
        password: '',
        errors: [],
    };

    handleChange = (event) => {
        let obj = this.state;
        let value = event.target.value;
        if (event.target.type === 'checkbox') {
            value = event.target.checked;
        }
        obj[event.target.name] = value;
        this.setState(obj);
    };

    validateForm() {
        let errors = [];
        if (this.state.password.length === 0) {
            errors.push('Please enter your password');
        }
        if (this.state.username.length === 0) {
            errors.push('Please enter your username');
        }
        this.setState({errors: errors});
        return errors.length === 0;
    }

    nullErrors = () => {
        this.setState({errors: []});
    };

    handleErrors() {
        setTimeout(this.nullErrors, 5000);
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({errors: []}); // Null them so we can start over!
        if (!this.validateForm()) {
            return this.handleErrors();
        }
        let body = {
            username: this.state.username,
            password: this.state.password
        };
        let url = "/api/v1/login";
        fetch(url, {
            method: "POST",
            headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
            body: JSON.stringify(body)
        })
            .then((response) => response.json())
            .then((responseData) => {
                if (responseData.code === 200 && responseData.data && typeof responseData.data === 'string') {
                    localStorage.setItem("session_id", responseData.data);
                    this.props.history.push("/");
                }
                if (responseData.code === 401) {
                    let errors = [];
                    errors.push('Login invalid');
                    this.setState({errors: errors});
                }
            });
    };

    async componentDidMount() {
        let id = this.props.match.params.id;
        this.passwordInput.focus();
        if (id) {
            const response = await fetch('/api/v1/user/' + id);
            const body = await response.json();
            this.setState({
                id: body.data.id,
                username: body.data.username,
            });
        } else {
            this.props.history.push('/user')
        }
    }

    render() {
        const {errors, username} = this.state;
        let alert = "";
        if (errors.length !== 0) {
            alert = <div className="message-container">
                <div className="alert alert-danger">
                    <ul>
                        {errors.map(function (item) {
                            return <li key={item}>{item}</li>
                        })}
                    </ul>
                </div>
            </div>;
        }
        return <div>
            {alert}
            <form className="form-horizontal" method="POST" onSubmit={this.handleSubmit}>
                <div className="userlist">
                    <div className="user">
                        <img className="user-img" alt={username}
                             src="/static/img/avatar.png"/>
                        {username}
                        <input
                            ref={(input) => { this.passwordInput = input; }}  id="password" type="password" className="form-control" name="password" required=""
                               value={this.state.password} onChange={this.handleChange}/>
                        <button type="submit" className="btn btn-primary">Login</button>
                    </div>
                </div>
            </form>
        </div>
            ;
    }
}

export default Create;
