import React, {Component} from 'react';

class SearchForm extends Component {
    render() {
        return <div className="searchform">
            <form method="GET" action="https://duckduckgo.com/" target="_blank">
                <div className="input-container">
                    <input className="homesearch" autoFocus="autofocus" placeholder="DuckDuckGo search..."
                           name="q" type="text"/>
                    <button type="submit">
                        Search
                    </button>
                </div>
            </form>
        </div>;
    }
}

export default SearchForm;
