import React, {Component} from "react";
import User from "./User";

class List extends Component {
    state = {
        items: null,
        requires_login: true
    };

    async componentDidMount() {
        this.requiresLogin();
        let link = '/api/v1/users';
        const response = await fetch(link);
        const body = await response.json();
        if (body.code === 401) {
            return this.props.history.push("/user");
        }
        if (body.code !== 404) {
            this.setState({items: body.data});
        }
    }

    requiresLogin() {
        if (localStorage.getItem('session_id')) {
            this.setState({requires_login: false});
        }
    }

    logout = () => {
        localStorage.removeItem('session_id');
        this.props.history.push("/user");
        return true;
    };

    render() {
        const {items, requires_login} = this.state;
        return <section className="module-container">
            <header>
                <div className="section-title">
                    Users

                </div>
                <div className="module-actions">
                    <button onClick={this.logout} title="" className="button" style={{display:requires_login ? 'none' : ''}}>
                        <i className="fa fa-lock"/><span>Logout</span></button>
                    <a href="/#/users/create" title="" className="button">
                        <i className="fa fa-plus"/><span>Add</span></a>
                    <a href="#/" className="button"><i className="fa fa-ban"/><span>Cancel</span></a>
                </div>
            </header>

            <table className="table table-hover">
                <thead>
                <tr>
                    <th>Username</th>
                    <th className="text-center" width="100" style={{display:requires_login ? 'none' : ''}}>Edit</th>
                    <th className="text-center" width="100" style={{display:requires_login ? 'none' : ''}}>Delete</th>
                </tr>
                </thead>
                <tbody>
                {items !== null && items.map(function (item) {
                    return <User item={item} key={item.id} requires_login={requires_login}/>
                })}
                </tbody>
            </table>
        </section>;
    }
}

export default List;
