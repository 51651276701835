import React, {Component} from "react";

class Links extends Component {
    state = {
        items: null,
        groups: []
    };

    async componentDidMount() {
        if (this.requiresLogin()) {
            return;
        }
        const url = '/api/v1/links';
        fetch(url, {
            method: "POST",
            headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
            body: JSON.stringify({session_id: localStorage.getItem("session_id")})
        })
            .then((response) => response.json())
            .then((responseData) => {
                if (responseData.code === 401) {
                    return this.props.history.push("/user");
                }
                if (responseData.code === 200 && responseData.data) {
                    this.setState({items: responseData.data});
                }
            });
    }

    requiresLogin() {
        if (!localStorage.getItem('session_id')) {
            this.props.history.push("/user");
            return true;
        }
        return false;
    }

    render() {
        const {items} = this.state;

        if (items !== null) {
            return <div id="sortable" className="ui-sortable ui-sortable-disabled">
                {items.map(link)}
            </div>;
        } else {
            return (<div id="sortable" className="ui-sortable ui-sortable-disabled"/>);
        }

        function link(item) {
            let divStyle = {
                backgroundColor: item.color,
            };
            if (!item.icon) {
                item.icon = "/static/icons/warden.png";
            }
            if (!item.color) {
                divStyle.backgroundColor = '#000';
            }
            return <section className="item-container ui-sortable-handle" data-id={item.id} key={item.id}>
                <div className="item" style={divStyle}>
                    <img className="app-icon" alt="" src={item.icon}/>
                    <div className="details">
                        <div className="title white">{item.name}</div>
                    </div>
                    <a className="link white" target="_blank" rel="noopener noreferrer"
                       href={item.url}>
                        <i className="far fa-arrow-alt-circle-right"/>
                    </a>
                </div>
                <a className="item-edit" href={item.id}>
                    <i className="fas fa-pencil"/>
                </a>

            </section>;
        }
    }
}

export default Links;
