import React, {Component} from "react";

class Create extends Component {
    state = {
        id: 0,
        username: '',
        password: '',
        password_confirm: '',
        active: true,
        role: 0,
        errors: [],
        title: "Add user",
        edit: false,
    };

    async componentDidMount() {
        let id = this.props.match.params.id;
        if (id) {
            const response = await fetch('/api/v1/user/' + id);
            const body = await response.json();
            if (body.success && body.data) {
                this.setState({
                    id: body.data.id,
                    username: body.data.username,
                    role: body.data.role,
                    active: body.data.active,
                    title: "Edit " + body.data.username,
                    edit: true,
                });
            } else {
                return this.props.history.push("/");
            }
        }
    }


    handleChange = (event) => {
        let obj = this.state;
        let value = event.target.value;
        if (event.target.type === 'checkbox') {
            value = event.target.checked;
        }
        obj[event.target.name] = value;
        this.setState(obj);
    };

    validateForm() {
        let errors = [];
        if (this.state.password !== this.state.password_confirm) {
            errors.push('Passwords do not match');
        }
        if (this.state.password.length < 6) {
            errors.push('Password is too short');
        }
        if (this.state.username.length < 4) {
            errors.push('Username is too short');
        }
        this.setState({errors: errors});
        return errors.length === 0;
    }

    nullErrors = () => {
        this.setState({errors: []});
    };

    handleErrors() {
        setTimeout(this.nullErrors, 5000);
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({errors: []}); // Null them so we can start over!
        if (!this.validateForm()) {
            return this.handleErrors();
        }
        let body = {
            username: this.state.username,
            password: this.state.password,
            session_id: localStorage.getItem("session_id")
        };
        let url = "/api/v1/user/add";
        if (this.state.id) {
            url = "/api/v1/user/" + this.state.id + "/edit";
            body.id = this.state.id;
        }
        fetch(url, {
            method: "POST",
            headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
            body: JSON.stringify(body)
        })
            .then((response) => response.json())
            .then((responseData) => {
                if (responseData.code === 401) {
                    return this.props.history.push("/user");
                }
                if (responseData.code === 200) {
                    this.props.history.push("/users/list");
                }
            });
    };

    render() {
        const {errors} = this.state;
        let alert = "";
        if (errors.length !== 0) {
            alert = <div className="message-container">
                <div className="alert alert-danger">
                    <ul>
                        {errors.map(function (item) {
                            return <li key={item}>{item}</li>
                        })}
                    </ul>
                </div>
            </div>;
        }
        return <div>
            {alert}
            <form method="POST" id="userform" onSubmit={this.handleSubmit}>
                <section className="module-container">
                    <header>
                        <div className="section-title">{this.state.title}</div>
                        <div className="module-actions">
                            <button type="submit" className="button"><i className="fa fa-save"/><span>Save</span>
                            </button>
                            <a href="#/users/list" className="button"><i className="fa fa-ban"/><span>Cancel</span></a>
                        </div>
                    </header>
                    <div id="create" className="create">
                        <div className="input">
                            <label>Username *</label>
                            <input placeholder="Username" id="appname" className="form-control" name="username"
                                   type="text"
                                   value={this.state.username} onChange={this.handleChange}/>
                        </div>
                        <div className="input">
                            <label>Password *</label>
                            <input name="password" placeholder="Password" className="form-control" type="password"
                                   value={this.state.password} onChange={this.handleChange}/>
                        </div>
                        <div className="input">
                            <label>Confirm Password *</label>
                            <input name="password_confirm" placeholder="Confirm Password" className="form-control"
                                   type="password" value={this.state.password_confirm} onChange={this.handleChange}/>
                        </div>
                        <div className="input">
                            <input name="active" placeholder="Active" className="form-control"
                                   type="checkbox" checked={this.state.active} onChange={this.handleChange}/><label>Active</label>
                        </div>

                    </div>
                    <footer>
                        <div className="section-title">&nbsp;</div>
                        <div className="module-actions">
                            <button type="submit" className="button"><i className="fa fa-save"/><span>Save</span>
                            </button>
                            <a href="#/users/list" className="button"><i className="fa fa-ban"/><span>Cancel</span></a>
                        </div>
                    </footer>
                </section>
            </form>
        </div>
            ;
    }
}

export default Create;
