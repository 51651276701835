import React, {Component} from "react";

class Buttons extends Component {
    render() {
        return <div id="config-buttons">
            {/*<a id="config-button" className="config" href="#/"><i className="fas fa-exchange-alt"/></a>*/}
            <a id="dash" className="config" href="#/"><i className="fas fa-th"/></a>
            <a id="items" className="config" href="#/items"><i className="fas fa-list"/></a>
            <a id="settings" className="config" href="#/users/list"><i className="fas fa-user"/></a>
            {/*<a id="settings" className="config" href="#/settings"><i className="fas fa-cogs"/></a>*/}
        </div>
    }
}
export default Buttons
