import React, {Component} from "react";
import PropTypes from "prop-types";

class Item extends Component {
    handleDelete = () => {
        const { item } = this.props;
        let deleteLink = "/api/v1/link/" + item.id + "/delete";
        fetch(deleteLink, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                id: item.id,
                session_id: localStorage.getItem("session_id")
            })
        }).then((response) => response.json()).then((responseData) => {
            if (responseData.code === 401) {
                return this.props.history.push("/user");
            }
            if (responseData.code === 200) {
                window.location.reload(true);
            }
        });
    };

    render() {
        const { item } = this.props;
        let editLink = "/#/items/" + item.id + "/edit";
        let title = "Edit" + item.name;

        return <tr key={item.id}>
            <td>{item.name}</td>
            <td><a href={item.url}>{item.url}</a></td>
            <td className="text-center">
                <a href={editLink} title={title}>
                    <i className="fas fa-edit"/></a></td>
            <td className="text-center">
                <button className="link" type="submit" onClick={this.handleDelete}>
                    <i className="fa fa-trash-alt"/>
                </button>
            </td>
        </tr>;
    }
}

Item.propTypes = {
    item: PropTypes.object.isRequired,
};

export default Item;
